import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// modules
import { SharedModule } from './shared/shared.module';
// auth
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider
} from 'angularx-social-login';
// translate module
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// components
import { HeaderComponent } from './components/header/header.component';
import { ShortMenuComponent } from './components/short-menu/short-menu.component';
import { LongMenuComponent } from './components/long-menu/long-menu.component';
import { LoginComponent } from './components/login/login.component';
import { RootComponent } from './components/root/root.component';
import { ScreenSizeErrorComponent } from './components/screen-size-error/screen-size-error.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
// interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {PaypalBuyDialogComponent} from './components/paypal-buy-dialog/paypal-buy-dialog.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ShortMenuComponent,
    LongMenuComponent,
    LoginComponent,
    RootComponent,
    ScreenSizeErrorComponent,
    MobileMenuComponent,
    PaypalBuyDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    // translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SocialLoginModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
  ],
  entryComponents: [PaypalBuyDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('465788035724-g5qiid6dkejl0n04ud291t4ijhg0t2r0.apps.googleusercontent.com')
  },
  //
  // {
  //   id: FacebookLoginProvider.PROVIDER_ID,
  //   provider: new FacebookLoginProvider("768892870678524")
  // },

]);

function provideConfig() {
  return config;
}
