import {ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {AppService} from '../../services/app.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class HeaderComponent implements OnInit {


  get titleTemplate(): TemplateRef<any> {

    return this.appService.headerTitleTemplate;
  }

  get actionsTemplate(): TemplateRef<any> {

      return this.appService.headerActionsTemplate;
  }

  @Input() mobileMenu: boolean;

  constructor(private appService: AppService) {
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.appService.toggleMenu();
  }

  changeLanguage(language: string) {
    this.appService.changeLanguage(language);
  }

}
