import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserTableInfo} from '../../../models/user/user-table-info';
import {Purchase} from '../../../modules/admin/models/purchase';

@Component({
  selector: 'app-purchases-table',
  templateUrl: './purchases-table.component.html',
  styleUrls: ['./purchases-table.component.scss']
})
export class PurchasesTableComponent implements OnInit {

  @Input() purchases: Purchase[];
  @Input() info: UserTableInfo;

  @Output() pageNumber = new EventEmitter<number>();

  constructor() { }

  clickToSwitchPage(pageNumber: number) {

    if (pageNumber !== this.info.currentPage) {
      this.pageNumber.emit(pageNumber);

    }

  }

  ngOnInit() {
  }

}
