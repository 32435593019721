import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) { }

  showMessage(message: string) {
    message = this.translateService.instant(message);
    this.snackBar.open(message, null, { duration: 3000});
  }

}
