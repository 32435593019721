import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppService} from '../../../services/app.service';
import {Combination} from '../../../models/combination';

@Component({
  selector: 'app-hands-range',
  templateUrl: './hands-range.component.html',
  styleUrls: ['./hands-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandsRangeComponent implements OnInit {

  @Input() combinations: Combination[];
  @Input() isEditable: boolean;

  constructor(private appService: AppService) { }

  ngOnInit() {
  }

  onCombinationClick(combinationIndex: number) {
    if (this.isEditable) {
      this.combinations[combinationIndex].isFilled = !this.combinations[combinationIndex].isFilled;
    }
  }

  onMouseOver(combinationIndex: number) {
    if (this.appService.isMouseDown$.value && !this.combinations[combinationIndex].isFilled && this.isEditable) {

      this.combinations[combinationIndex].isFilled = true;
    }
  }

}
