import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timeToDays'
})
export class TimeToDaysPipe implements PipeTransform {

  transform(hours: number): string {
    if (hours < 24) {
      const prefix = hours === 1 ? ' hour' : ' hours';
      return hours.toString() + prefix;
    } else {
      const days = Math.floor(hours / 24);
      const prefix = days === 1 ? ' day' : ' days';
      return  days.toString() + prefix;
    }
  }
}
