import {Injectable} from '@angular/core';
import {SplitAnswer} from '../../modules/charts/models/split-answer';
import {Answer} from '../../modules/charts/models/answer';
import * as _ from 'lodash';
import {SnackbarService} from './snackbar.service';
import {ApiService} from '../../services/api.service';
import {Observable} from 'rxjs';
import {HandsRange} from '../../models/hands-range';
import {Combination} from '../../models/combination';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  savedChart: {combinations?: string[], answers?: Answer[], splitAnswers?: SplitAnswer[], action?: number} = undefined;
  savedRange: {combinations?: Combination[]};

  constructor(private snackbarService: SnackbarService, private apiService: ApiService) {
  }

  saveChart(combinations: string[], answers: Answer[], splitAnswers: SplitAnswer[], action?: number) {

    this.savedChart = {};
    this.savedChart.combinations = _.cloneDeep(combinations);
    this.savedChart.answers = _.cloneDeep(answers);
    this.savedChart.splitAnswers = _.cloneDeep(splitAnswers);
    this.savedChart.action = action;
    this.snackbarService.showMessage('Chart is saved in buffer');
  }

  getSavedChart(): {combinations?: string[], answers?: Answer[], splitAnswers?: SplitAnswer[], action?: number} {

    return _.cloneDeep(this.savedChart);
  }

  saveRange(combinations: Combination[]) {
    this.savedRange = {};
    this.savedRange.combinations = _.cloneDeep(combinations);
  }

  getSavedRange(): {combinations?: Combination[]} {
    return _.cloneDeep(this.savedRange);
  }

  getHandsRangeById(id: number): Observable<HandsRange> {
    return this.apiService.getHandsRangeById(id);
  }

  saveHandsRange(range: HandsRange): Observable<number> {
    return this.apiService.saveHandsRange(range, 1);
  }

}
