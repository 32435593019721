import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {PAYPAL} from '../../../app.constants';
import {UserService} from '../../services/user.service';
import {ApiService} from '../../services/api.service';
import {PokerHeroProduct, PokerHeroPromo, PokerHeroPurchase} from './paypal-buy-dialog.model';
import {PRODUCTS} from './products';
import {PROMO_CODES} from './promo-codes';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {SnackbarService} from '../../shared/services/snackbar.service';

@Component({
  selector: 'app-paypal-buy-dialog',
  templateUrl: './paypal-buy-dialog.component.html',
  styleUrls: ['./paypal-buy-dialog.component.scss']
})
export class PaypalBuyDialogComponent implements OnInit {

  products: PokerHeroProduct[] = PRODUCTS;
  promoCodes: PokerHeroPromo[] = PROMO_CODES;
  productId = 'ph-sub-1';
  purchase: PokerHeroPurchase;
  promoCtrl = new FormControl('');

  @ViewChild('paypalControls', {static: true}) paypalControls!: ElementRef;

  constructor(private apiService: ApiService,
              private userService: UserService,
              private router: Router,
              private snackbarService: SnackbarService,
              public dialogRef: MatDialogRef<PaypalBuyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {productId: string}) {
    this.productId = data.productId;
  }

  ngOnInit() {
    const productForPurchase = this.products.find(product => product.id === this.productId);
    this.purchase = {
      product: productForPurchase,
      discount: 0,
      sum: productForPurchase.cost,
      promo: '',
    };
    this.setPayPalButtons(this.purchase);
  }

  setPayPalButtons(purchase: PokerHeroPurchase) {
    if (this.paypalControls.nativeElement) {
      this.paypalControls.nativeElement.innerHTML = '<div></div>';
    }

    PAYPAL.Buttons(
      {
        style: {
          layout: 'vertical',
          color: 'gold',
          shape: 'rect',
          label: 'paypal',
        },
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: purchase.sum,
                  currency_code: 'USD'
                }
              }
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        },
        onApprove: (data: any, actions: any) => {
          return actions.order.capture().then((details: any) => {
            if (details.status === 'COMPLETED') {
              this.addPurchase();
            }
          });
        },
        onError: (error: any) => {
          console.log(error);
        }
      }
    ).render(this.paypalControls.nativeElement);
  }

  addPurchase() {
    this.apiService.registerPurchase({
      date: new Date(),
      email: this.userService.getUser().email,
      paymentMethod: { title: 'PayPal', id: 14 },
      sum: this.purchase.sum,
      product: { id: null, title: this.purchase.product.id },
      promocode: this.purchase.promo,
      comment: '',
    }).subscribe(() => {
      this.handleSuccessPurchase(this.productId);
    });
  }

  openDiscord() {
    window.open('https://discord.gg/pokerhero', '_blank');
  }

  openSkype() {
    window.open('https://join.skype.com/invite/hUXNncXpufCR', '_blank');
  }

  applyPromoCode() {
    const userPromoCode = this.promoCtrl.value;
    const existingPromo = this.promoCodes.find(code =>
      code.codes.some(value => value.toLowerCase() === userPromoCode.toLowerCase()));
    if (existingPromo) {
      this.purchase.discount = existingPromo.discount;
      this.purchase.promo = userPromoCode;
      this.purchase.sum = this.purchase.product.cost - (this.purchase.product.cost / 100 * this.purchase.discount);
      this.setPayPalButtons(this.purchase);
      this.promoCtrl.reset();
    }
  }

  handleSuccessPurchase(productId: string) {
    const product = this.products.find(value => value.id === productId);
    this.userService.checkInvite().subscribe(() => {
      this.snackbarService.showMessage(`${product.title} is added`);
      this.router.navigate(['training']);
      this.dialogRef.close();
    });
  }
}
