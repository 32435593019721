import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {Combination} from '../../../models/combination';
import {HandsRange} from '../../../models/hands-range';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DefaultDataService} from '../../../services/default-data.service';
import {LessonService} from '../../../modules/training/services/lesson.service';
import {ChartService} from '../../services/chart.service';
import {SnackbarService} from '../../services/snackbar.service';

@Component({
  selector: 'app-game-mode-hands-range-dialog',
  templateUrl: './game-mode-hands-range-dialog.component.html',
  styleUrls: ['./game-mode-hands-range-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameModeHandsRangeDialogComponent implements OnInit {

  combinations: Combination[];
  title: string;
  currentHandsRangeId: number;

  isEditable: boolean;

  radioCtrl: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<GameModeHandsRangeDialogComponent>,
              private defaultDataService: DefaultDataService,
              private chartService: ChartService,
              private snackbarService: SnackbarService,
              private lessonService: LessonService, private changeDetectorRef: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: {currentHandsRangeId: number, title: string}) {
    this.title = data.title;
    this.currentHandsRangeId = data.currentHandsRangeId;
    if (data.currentHandsRangeId !== 1) {
      this.isEditable = true;
    }
  }

  ngOnInit() {
    this.loadHandsRange(this.currentHandsRangeId);
    this.initFormGroup();
  }

  loadHandsRange(id: number) {
    this.chartService.getHandsRangeById(id).subscribe((data: HandsRange) => {
      this.combinations = data.combinations;
      this.changeDetectorRef.detectChanges();
    });
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

  reset() {
    this.combinations = this.combinations.map((combination: Combination) => {
      combination.isFilled = false;
      return combination;
    });
    this.changeDetectorRef.detectChanges();
  }

  copy() {
    this.chartService.saveRange(this.combinations);
    this.snackbarService.showMessage('Range is saved in buffer');
  }

  paste() {
    const savedRange = this.chartService.getSavedRange();
    if (savedRange && savedRange.combinations) {
      this.combinations = savedRange.combinations;
      this.changeDetectorRef.detectChanges();
    } else {
      this.snackbarService.showMessage('No range in buffer');
    }
  }

  fillAll() {
    this.combinations = this.combinations.map((combination: Combination) => {
      combination.isFilled = true;
      return combination;
    });
    this.changeDetectorRef.detectChanges();
  }

  onSaveButtonClicked() {
    if (this.radioCtrl.value === 1) {
      this.dialogRef.close(1);
    } else {
      const newHandsRange: HandsRange = {};
      newHandsRange.id = this.currentHandsRangeId === 1 ? null : this.currentHandsRangeId;
      newHandsRange.combinations = this.combinations;
      newHandsRange.title = this.title;
      this.chartService.saveHandsRange(newHandsRange).subscribe((id: number) => {
        this.dialogRef.close(id);
      });
    }

  }

  initFormGroup() {
    this.radioCtrl = new FormControl(this.currentHandsRangeId === 1 ? 1 : 2);

    this.radioCtrl.valueChanges.subscribe((newValue: number) => {
      this.isEditable = newValue !== 1;
      if (newValue !== 1) {
        this.reset();
      } else {
        this.fillAll();
      }
    });
  }

}
