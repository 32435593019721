import {Pipe, PipeTransform} from '@angular/core';
import {ChartAction} from '../../models/chart/chart-action';

@Pipe({
  name: 'actionView'
})
export class ActionViewPipe implements PipeTransform {

  transform(action: ChartAction): string {
    return action ? action.title : 'first move';
  }
}
