import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Answer} from '../../../modules/charts/models/answer';
import {DefaultDataService} from '../../../services/default-data.service';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportDialogComponent implements OnInit {

  importData: Array<ImportData> = [];
  importCombinations: Array<ImportCombination> = [];

  constructor(public dialogRef: MatDialogRef<ImportDialogComponent>, private defaultDataService: DefaultDataService,
              @Inject(MAT_DIALOG_DATA) public data: Answer[]) {
    data.forEach((value: Answer) => {
      this.importData.push({answer: value, importValue: ''});
    });
    const defaultCombinations = this.defaultDataService.getDefaultCombinations();
    defaultCombinations.forEach(combination => {
      this.importCombinations.push({id: combination.id, values: []});
    });
  }

  ngOnInit() {
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

  startImport() {
    this.importData = this.importData.sort((a, b) =>
      this.getAnswerPriority(b.answer.value) - this.getAnswerPriority(a.answer.value));

    this.importData.forEach((data: ImportData) => {
      const weightArray = this.fetchWeightArray(data.importValue);
      const values = this.fetchDataFromWeightArray(weightArray, data.answer);
      this.updateImportCombinations(values);
    });
    const importedChart = this.generateChart(this.importCombinations);
    this.dialogRef.close(importedChart);
  }

  generateChart(importCombination): Array<string> {
    const combinations = this.defaultDataService.getEmptyChart();
    importCombination.forEach((importComb: ImportCombination) => {
      const type = importComb.values.length > 1 ? 'y' : 'n';
      let answers = '';
      if (importComb.values.length === 0) {
        answers = 'f';
      }
      if (importComb.values.length === 1) {
        answers = importComb.values[0].answer.value;
      }
      if (importComb.values.length > 1) {
        let currentWeight = 0;
        importComb.values.forEach((value, i) => {
          if (i === 0) {
            answers = answers + value.answer.value + '(' + value.weight + ')';
            currentWeight = value.weight;
          }
          if (i === importComb.values.length - 1) {
            answers = answers + '-' + value.answer.value;
          }
          if (i !== importComb.values.length - 1 && i !== 0) {
            currentWeight += value.weight;
            answers = answers + '-' + value.answer.value + '(' + currentWeight + ')';
          }
        });
      }
      combinations[importComb.id] = combinations[importComb.id] + ',' + type + ',' + answers;
    });
    return combinations;
  }

  updateImportCombinations(values: Array<{id: number, answer: Answer, weight: number}>) {
    values.forEach((value: {id: number, answer: Answer, weight: number}) => {
      const index = this.importCombinations.findIndex((comb: ImportCombination) => comb.id === value.id);
      this.importCombinations[index].values.push({answer: value.answer, weight: value.weight});
    });
  }

  fetchWeightArray(value: string): Array<string> {
    return value.replace(/\s/g, '').split('],');
  }

  fetchDataFromWeightArray(weightArray: Array<string>, answer: Answer): Array<{id: number, answer: Answer, weight: number}> {
    const newValues: Array<{id: number, answer: Answer, weight: number}> = [];
    weightArray.filter(data => data.length >  0).forEach((data) => {
      if (data[0] === '[') {
        const index = data.indexOf(']');
        const weight = data.substr(1, index - 1);
        const postFixLength = data[data.length - 1] === ']' ? weight.length + 2 : weight.length + 1;

        let combinations = data.substr(weight.length + 2, data.length - postFixLength).split(',');
        combinations = this.fetchCombination(combinations);
        combinations = this.deleteDuplicates(combinations);

        combinations.forEach((combination: string) => {
          newValues.push({
            id: this.defaultDataService.getCombinationByTitle(combination, this.revertCombination(combination)).id,
            answer,
            weight: +weight
          });
        });

      } else {
        const weight = 100;
        let combinations = data.split(',');
        combinations = this.fetchCombination(combinations);
        combinations = this.deleteDuplicates(combinations);

        combinations.forEach((combination: string) => {
          newValues.push({
            id: this.defaultDataService.getCombinationByTitle(combination, this.revertCombination(combination)).id,
            answer,
            weight: +weight
          });
        });
      }

    });

    return newValues;
  }

  fetchCombination(combinations: Array<string>): Array<string> {
    return combinations.map((combination: string) => {
      let postfix = combination[1] === combination[3] ? 's' : 'o';
      if (combination[0] === combination[2]) {
        postfix = '';
      }
      return combination[0] + combination[2] + postfix;
    });
  }

  deleteDuplicates(combinations: Array<string>): Array<string> {
    return combinations.filter((value, i) => {
      return combinations.indexOf(value) === i;
    });
  }

  getAnswerPriority(value: string): number {
    switch (value) {
      case 'a':
        return 20;
      case 'r':
        return 16;
      case 'r2':
        return 12;
      case 'r3':
        return 8;
      case 'ch':
      case 'c':
        return 4;
      case 'f':
        return 0;
    }
  }

  revertCombination(combination: string): string {
    const postfix = combination[2] ? combination[2] : '';
    return combination[1] + combination[0] + postfix;
  }
}

interface ImportData {
  answer: Answer;
  importValue: string;
}

interface ImportCombination {
  id: number;
  values: Array<{weight: number, answer: Answer}>;
}
