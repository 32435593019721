import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() label: string;

  @Input() items: Array<any>;

  @Input() control: AbstractControl;

  @Output() changeItem = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onChangeItem(item) {
    this.changeItem.emit(item);
  }

}
