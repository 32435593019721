import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'oppsTitle'
})
export class OppsTitlePipe implements PipeTransform {

  titles = ['sb vs bb', 'sb vs btn os', 'bb vs btn mr', 'bb vs sb mr',
    'bb vs sb limp', 'bb vs sb os', 'bb vs btn os', 'bb vs btn limp', 'sb vs btn mr', 'bb vs sb 3x'];

  transform(value: string, chartTitle: string): string {
    if (value === 'reg + reg' || value === 'fish + fish') {
      if (this.titles.indexOf(chartTitle.toLowerCase()) !== -1) {
        value = value.split(' ')[0];
      }
      return value;
    } else {
      return value;
    }
  }
}
