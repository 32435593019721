import {PokerHeroProduct} from './paypal-buy-dialog.model';

export const PRODUCTS: PokerHeroProduct[] = [
  {
    id: 'ph-mtt-sub-1',
    title: '1 Month Premium',
    description: '',
    cost: 20,
  },
  {
    id: 'ph-mtt-sub-3',
    title: '3 Months Premium',
    description: '',
    cost: 45,
  },
  {
    id: 'ph-mtt-sub-12',
    title: '12 Months Premium',
    description: '',
    cost: 150,
  },
];
