import {Component, HostListener, OnInit} from '@angular/core';
import {AppService} from '../../services/app.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  viewMenu: boolean;

  constructor(private appService: AppService, private router: Router) {

    this.viewMenu = window.innerWidth >= 720;

    this.appService.setLanguages();
    if (this.router.url === '/') {
      this.router.navigate(['training']);
    }
  }

  get isOpenMenu$() {
    return this.appService.menuOpened$.asObservable();
  }

  @HostListener('window:mouseup') onMouseUp() {
    this.appService.changeMouseDownState(false);
  }

  @HostListener('window:mousedown') onMouseDown() {
    this.appService.changeMouseDownState(true);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width) {
    this.viewMenu = width >= 720;
  }

  ngOnInit(): void {
  }
}
