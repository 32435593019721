import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../models/user/user';
import {tap} from 'rxjs/operators';
import {UserSettings} from '../models/user/user-settings';
import {DefaultDataService} from './default-data.service';
import {ViewInfo} from '../models/user/view-info';
import {ChartPack} from '../models/chart/chart-pack';
import {AppService} from './app.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = new BehaviorSubject<User>(null);
  token = '';

  constructor(private apiService: ApiService, private appService: AppService,
              private defaultDataService: DefaultDataService) { }

  checkInvite(): Observable<{isExist: boolean, user: User}> {
    return this.apiService.checkInvite().pipe(
      tap((data: {isExist: boolean, user: User}) => {
        this.user.next(data.user);
        this.token = localStorage.getItem('token');
        if (this.getUser().language) {
          this.appService.changeLanguage(this.getUser().language);
          localStorage.setItem('lang', this.getUser().language);
        }
      })
    );
  }

  checkAuth(code: string, ref: string) {
    return this.apiService.checkAuth(code, ref).pipe(
      tap((data: {isExist: boolean, user: User, token: string}) => {
        this.user.next(data.user);
        this.token = data.token;
        if (this.getUser().language) {
          this.appService.changeLanguage(this.getUser().language);
          localStorage.setItem('lang', this.getUser().language);
        }
      })
    );
  }

  isAdmin(): boolean {
    return this.user.value.isAdmin;
  }

  isPartner(): boolean {
    return  this.user.value.partner;
  }

  isCoach(): boolean {
    return  this.user.value.coach;
  }

  isSFCoach(): boolean {
    return  this.user.value.isSFCouch;
  }

  isMVPCoach(): boolean {
    return  this.user.value.isMVPCouch;
  }

  isBTBCoach(): boolean {
    return  this.user.value.isBTBCouch;
  }

  isFireStormCouch(): boolean {
    return  this.user.value.isFireStormCouch;
  }

  isTrial(): boolean {
    return this.user.value.isTrial;
  }

  getToken(): string {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

  getSettings(): UserSettings {
    return this.user.value.settings;
  }

  getViewInfo(): ViewInfo {
    const settings: UserSettings = this.getSettings();
    const viewInfo: ViewInfo = {};
    viewInfo.isStackInBB = this.defaultDataService.isStackInBB(settings.stackView);
    viewInfo.roundTo = settings.roundTo;
    viewInfo.isEffectiveStackShown = settings.isEffectiveStackShown;
    viewInfo.symbol = settings.stackView.symbol;
    return viewInfo;
  }

  getUser(): User {
    return this.user.value;
  }

  isStackInBB(user: User): boolean {
    return this.defaultDataService.isStackInBB(user.settings.stackView);
  }

  updateUser(user: User): Observable<User> {
    return this.apiService.updateUser(user);
  }

  applyPromocodeMVP(promocode: string): Observable<any> {
    return this.apiService.applyPromocodeMVP(promocode);
  }

  isSPNSChartExist(): boolean {
    return this.isChartExist('c7804228-b578-4744-99b3-55ae9b8c5786');
  }

  isSPNSCourseChartExist(): boolean {
    return this.isChartExist('573eadc6-77d2-48a7-b4fe-e792e0217ecb');
  }

  isAsymmetricChartExist(): boolean {
    return this.isChartExist('225b086a-0ef0-40e8-b4ae-4b82b018afcf');
  }

  isSawCrewChartExist(): boolean {
    return this.isChartExist('0ad1a501-d8a8-4996-b8dd-df4e651f36d2');
  }

  isGtoPackExist(): boolean {
    return this.isChartExist('8bf4e61c-6e22-443a-8539-f0a2ca27e041');
  }

  isChartExist(uuid: string): boolean {
    if (!this.user.value.chartGroups || this.user.value.chartGroups.length === 0) {
      return false;
    }
    return this.user.value.chartGroups.find(
      (chart: ChartPack) => chart.uuid === uuid) !== undefined;
  }

  subDaysLeft(): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const today = new Date();
    if (this.user.value.subTo === null || this.user.value.subTo.getTime() - today.getTime() < 0) {
      return 0;
    }
    return Math.round(Math.abs((this.user.value.subTo.getTime() - today.getTime()) / oneDay));
  }

}
