import {Component, Input, OnInit} from '@angular/core';
import {Player} from '../../../models/player';

@Component({
  selector: 'app-player-hud',
  templateUrl: './player-hud.component.html',
  styleUrls: ['./player-hud.component.scss']
})
export class PlayerHudComponent implements OnInit {

  @Input() player: Player;
  @Input() viewInfo: {isStackInBB: boolean, bb?: number, roundTo?: number, symbol: string};
  @Input() reverse: boolean;
  @Input() isLeft: boolean;

  layoutCard: string;

  constructor() { }

  ngOnInit() {
    this.layoutCard = localStorage.getItem('layoutCard');
  }

}
