import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DefaultDataService} from '../../../services/default-data.service';
import {Chips} from '../../../models/chips';

@Component({
  selector: 'app-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit, OnChanges {

  @Input() chipsCount: number;

  defaultChips: Chips[] = [];
  chips: Chips[] = [];
  chipsArrays: Array<Chips[]> = [];

  constructor(private defaultDataService: DefaultDataService) {
    this.defaultChips = this.defaultDataService.getDefaultChips();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.chips = [];
    this.chipsArrays = [];
    this.generateChips(this.chipsCount);
  }

  generateChips(count: number) {
    this.defaultChips.forEach((chip: Chips) => {
      while (count >= chip.nominal) {
        this.chips.push(chip);
        count -= chip.nominal;
      }
    });

    while (this.chips.length > 5) {
      const newPart = this.chips.splice(0, 5);
      this.chipsArrays.push(newPart);
    }

    this.chipsArrays.push(this.chips);
  }

}
