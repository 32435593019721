import {Component, Input, OnInit} from '@angular/core';
import {Player} from '../../../models/player';
import {ViewInfo} from '../../../models/user/view-info';

@Component({
  selector: 'app-poker-table',
  templateUrl: './poker-table.component.html',
  styleUrls: ['./poker-table.component.scss']
})
export class PokerTableComponent implements OnInit {

  @Input() hero: Player;
  @Input() opponent1: Player;
  @Input() opponent2: Player;
  @Input() opponent3: Player;
  @Input() opponent4: Player;
  @Input() opponent5: Player;
  @Input() opponent6: Player;
  @Input() opponent7: Player;
  @Input() opponent8: Player;

  @Input() pot: number;
  @Input() effectiveStack: number;
  @Input() info: {questions: number, correct: number} = {questions: 0, correct: 0};
  @Input() viewInfo: ViewInfo;

  mode: string;

  constructor() { }

  ngOnInit() {
    this.mode = localStorage.getItem('layoutCard') ? localStorage.getItem('layoutCard') : 'classic';
  }

}
