import { Injectable } from '@angular/core';
import {Chart} from '../../models/chart/chart';
import {Action, ActionGroup, ActionGroupByTitle} from '../../models/action/action';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  groupPackLessons(charts: Chart[], addStats = false): ActionGroup[] {

    const groupsMap: Map<string, ActionGroup> = charts.reduce((resultMap, chart: Chart) => {

      const key: string = chart.action.heroPosition.title;

      chart.action.lessonId = chart.id;

      if (!resultMap.has(key)) {

        const sortPriority: number = this.getSortPriority(chart.action.heroPosition.id);

        resultMap.set(key, {actions: [], position: chart.action.heroPosition, sortPriority});
      }
      chart.action.stats = {hands: chart.hands, mistakes: chart.mistakes, winRate: chart.winRate};
      resultMap.get(key).actions.push(chart.action);

      return resultMap;
    }, new Map());

    const groups: ActionGroup[] = Array.from(groupsMap.values());

    groups.forEach((group: ActionGroup) => {

      const groupsActionMap: Map<string, ActionGroupByTitle> = group.actions.reduce((resultMap, action) => {

        const key: string = action.title;

        if (!resultMap.has(key)) {

          resultMap.set(key, {actions: [], title: key});
        }

        resultMap.get(key).actions.push(action);

        return resultMap;
      }, new Map());

      group.actionGroups = Array.from(groupsActionMap.values());
    });

    groups.sort((group1: ActionGroup, group2: ActionGroup): number => {
      return group1.sortPriority - group2.sortPriority;
    });

    for (const group of groups) {
      for (const actionGroup of group.actionGroups) {
        actionGroup.actions.sort((action1: Action, action2: Action): number => {
          return action2.heroStack - action1.heroStack;
        });
      }
    }

    if (addStats) {
      groups.forEach((group: ActionGroup) => {

        group.actionGroups.forEach((currentGroup) => {
          currentGroup.stats = {};
          currentGroup.stats.hands = currentGroup.actions.reduce((sum: number, action: Action) => {
            return sum + action.stats.hands;
          }, 0);

          const totalMistakes = currentGroup.actions.reduce((sum: number, action: Action) => {
            return sum + action.stats.mistakes;
          }, 0);
          currentGroup.stats.winRate = currentGroup.stats.hands > 0 ? 100 - Math.round(totalMistakes * 100 / currentGroup.stats.hands) : 0;
        });

      });
    }

    return groups;
  }

  getSortPriority(positionId: number): number {
    switch (positionId) {
      case 0:
        return 0;
      case 1:
        return 5;
      case 2:
        return 15;
      case 3:
        return 20;
      case 4:
        return 25;
      case 5:
        return 30;
      case 6:
        return 35;
      case 7:
        return 40;
      case 8:
        return 45;
    }
  }

  isToolTipAvailable(combination: string): boolean {
    const parts = combination.split(',');
    if (!parts[1]) {
      return false;
    }
    return parts[1] === 'n';
  }

  getToolTipForCombination(combination: string): string {
    const parts = combination.split(',');
    if (!parts[1]) {
      return '';
    }

    if (parts[1] === 'n') {
      return '';
    } else {
      const combinationParts = [];
      let toolTip = '';
      const answers = parts[2].split('-');
      answers.forEach((answer: string, i: number) => {
        let symbol;
        let answerWidth;
        if (i === 0) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1);
        }
        if (i !== 0 && i !== answers.length - 1) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1)
            - combinationParts[combinationParts.length - 1];
        }
        if (i === answers.length - 1) {
          answerWidth = 100 - combinationParts.reduce((sum, combinationPart) => sum + combinationPart, 0);
        }

        combinationParts.push(answerWidth);
        toolTip += answerWidth.toString();
        if (i !== answers.length - 1) {
          toolTip += '/';
        }
      });
      return toolTip;
    }
  }

}

export interface ChartGroup {
  charts: Chart[];
  title: string;
  sortPriority: number;
  hands?: number;
  winRate?: number;
}
