import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../../models/user/user';
import {UserTableInfo} from '../../../models/user/user-table-info';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {

  @Input() users: User[];
  @Input() info: UserTableInfo;
  @Input() isDeleteUserFromSchoolButton?: boolean;

  @Output() pageNumber = new EventEmitter<number>();
  @Output() deletedUserId = new EventEmitter<number>();

  constructor() { }

  clickToSwitchPage(pageNumber: number) {

    if (pageNumber !== this.info.currentPage) {
      this.pageNumber.emit(pageNumber);

    }

  }

  clickDeleteUserFromSchool(userId: number) {
    this.deletedUserId.emit(userId);
  }

  ngOnInit() {
  }

}
