import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-finish-lesson-dialog',
  templateUrl: './finish-lesson-dialog.component.html',
  styleUrls: ['./finish-lesson-dialog.component.scss']
})
export class FinishLessonDialogComponent implements OnInit {

  stars = 0;

  events = FINISH_LESSON_EVENTS;

  get feedback() {
    switch (this.stars) {
      case 0:
        return 'Poor result';
      case 1:
        return 'Not bad';
      case 2:
        return 'You can better!';
      case 3:
        return 'Perfect!';
    }
  }

  constructor(public dialogRef: MatDialogRef<FinishLessonDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {stars: number}) {
    this.stars = data.stars;
  }

  ngOnInit() {
  }

  close(event: FINISH_LESSON_EVENTS) {
    this.dialogRef.close(event);
  }

}

export enum FINISH_LESSON_EVENTS {
  RESTART = 'restart',
  NEXT = 'next'
}
