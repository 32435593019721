import {ChangeDetectionStrategy, Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-short-menu',
  templateUrl: './short-menu.component.html',
  styleUrls: ['./short-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class ShortMenuComponent implements OnInit {

  get isAdmin() {
    return this.userService.isAdmin();
  }

  get isPartner() {
    return this.userService.isPartner();
  }

  get isCoach() {
    return this.userService.isCoach();
  }

  get isSFCoach() {
    return this.userService.isSFCoach();
  }

  get isBTBCoach() {
    return this.userService.isBTBCoach();
  }

  get isFireStormCouch() {
    return this.userService.isFireStormCouch();
  }

  get isMVPCouch() {
    return this.userService.isMVPCoach();
  }

  @HostBinding('class.app-short-menu') isDefaultClassUsed = true;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

}
