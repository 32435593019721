import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit} from '@angular/core';
import {DefaultDataService} from '../../../services/default-data.service';

@Component({
  selector: 'app-chart-cell',
  templateUrl: './chart-cell.component.html',
  styleUrls: ['./chart-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartCellComponent implements OnInit, OnChanges {

  @Input() combination: string;
  @Input() isSigned = false;

  toolTip = 'empty';
  combinationTitle = '';
  combinationParts: Array<{color: string, width: number}> = [];

  constructor(private defaultDataService: DefaultDataService) { }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.fillParts();
  }

  fillParts() {
    this.combinationParts = [];
    const parts = this.combination.split(',');
    if (!this.combinationTitle) {
      const combinationId = parts[0].substr(1);
      this.combinationTitle = this.defaultDataService.getCombinationTitleById(+combinationId);
    }
    if (!parts[1]) {
      return;
    }
    if (parts[1] === 'n') {
      this.combinationParts = [{color: this.getColor(parts[2]), width: 100}];
    } else {
      const answers = parts[2].split('-');
      let currentWeight = 0;
      answers.forEach((answer: string, i: number) => {
        let symbol;
        let answerWidth;
        if (i === 0) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1);
          currentWeight = answerWidth;
        }
        if (i !== 0 && i !== answers.length - 1) {
          symbol = answer[2] === '(' ? answer.substr(0, 2) : answer[0];
          const helpIndex = answer.indexOf(')');
          answerWidth = +answer.substr(symbol.length + 1, helpIndex - symbol.length - 1)
            - currentWeight;
          currentWeight += answerWidth;
        }
        if (i === answers.length - 1) {
          symbol = answer;
          answerWidth = 100 - this.combinationParts.reduce((sum, combinationPart) => sum + combinationPart.width, 0);
        }
        this.combinationParts.push({color: this.getColor(symbol), width: answerWidth});
      });
    }
  }

  getColor(symbol: string) {
    return this.defaultDataService.getColorByCombinationSymbol(symbol);
  }
}
