import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Combination} from '../../../models/combination';
import {HandsRange} from '../../../models/hands-range';
import {FormControl} from '@angular/forms';
import {DefaultDataService} from '../../../services/default-data.service';
import {LessonService} from '../../../modules/training/services/lesson.service';

@Component({
  selector: 'app-hands-filter-dialog',
  templateUrl: './hands-filter-dialog.component.html',
  styleUrls: ['./hands-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HandsFilterDialogComponent implements OnInit {

  combinations: Combination[];
  handsRanges: HandsRange[];
  initialHandsRange: HandsRange;

  rangeCtrl: FormControl = new FormControl();
  titleCtrl: FormControl = new FormControl();

  isEditable: boolean;

  constructor(public dialogRef: MatDialogRef<HandsFilterDialogComponent>, private defaultDataService: DefaultDataService,
              private lessonService: LessonService, private changeDetectorRef: ChangeDetectorRef,
              @Inject(MAT_DIALOG_DATA) public data: {currentHandsRange: HandsRange}) {
    this.initialHandsRange = data.currentHandsRange;
  }

  ngOnInit() {
    this.loadHandsRanges();
  }

  loadHandsRanges() {
    this.lessonService.getHandsRanges().subscribe((ranges: HandsRange[]) => {
      this.handsRanges = ranges;
      this.initFormGroup();
      this.changeDetectorRef.detectChanges();
    });
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

  reset() {
    this.combinations = this.combinations.map((combination: Combination) => {
      combination.isFilled = false;
      return combination;
    });
    this.changeDetectorRef.detectChanges();
  }

  onSaveButtonClicked() {
    const newHandsRange: HandsRange = this.rangeCtrl.value;
    newHandsRange.title = this.titleCtrl.value;
    this.dialogRef.close(newHandsRange);
  }

  initFormGroup() {
    const initialIndex = this.handsRanges.findIndex((handsRange: HandsRange) =>
      handsRange.id === this.initialHandsRange.id);
    this.rangeCtrl = new FormControl(this.handsRanges[initialIndex]);
    this.titleCtrl = new FormControl({value: this.handsRanges[initialIndex].title, disabled: !this.handsRanges[initialIndex].isEditable});
    this.combinations = this.handsRanges[initialIndex].combinations;
    this.isEditable = this.handsRanges[initialIndex].isEditable;

    this.rangeCtrl.valueChanges.subscribe((value: HandsRange | string) => {
      if (typeof value === 'string') {
        this.rangeCtrl.setValue({
          combinations: this.defaultDataService.getDefaultCombinations(),
          isEditable: true
        });
        this.isEditable = true;
        this.changeDetectorRef.detectChanges();
      } else {
        this.combinations = value.combinations;
        this.titleCtrl.setValue(value.title);
        if (value.isEditable) {
          this.titleCtrl.enable();
        } else {
          this.titleCtrl.disable();
        }
        this.isEditable = value.isEditable;
        this.changeDetectorRef.detectChanges();
      }

    });
  }

}
