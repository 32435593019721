import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Answer} from '../../../modules/charts/models/answer';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-answer-creating-dialog',
  templateUrl: './answer-creating-dialog.component.html',
  styleUrls: ['./answer-creating-dialog.component.scss']
})
export class AnswerCreatingDialogComponent implements OnInit {

  answers: Answer[];

  trackAnswersFn = trackById;

  answerFormGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<AnswerCreatingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Answer[]) {

    this.answers = data;

  }

  ngOnInit() {
    this.initFormGroup();
  }

  private initFormGroup() {
    const typeCtrl = new FormControl(null, Validators.required);
    const factor = new FormControl({value: null, disabled: true}, [Validators.required,
      Validators.min(2)]);

    this.answerFormGroup = new FormGroup({
      typeCtrl,
      factor
    });

    typeCtrl.valueChanges.pipe(
      tap((newValue: Answer) => {
        if (newValue.id === 1) {
          factor.enable();
        } else {
          factor.disable();
        }
      })
    ).subscribe();
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }

  onFinishButtonClick() {

    const newAnswer: Answer = {
      id: this.answerFormGroup.get('typeCtrl').value.id,
      title: this.answerFormGroup.get('typeCtrl').value.title,
      factor: this.answerFormGroup.get('factor').value,
      color: this.answerFormGroup.get('typeCtrl').value.color
    };
    this.dialogRef.close(newAnswer);
  }

}

export function trackById(index: number, { id }: { id: number }): number { return id; }
