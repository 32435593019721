import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(product: string): string {

    switch (product) {
      case 'ph-mtt-sub-1':
        return '1 month';
      case 'ph-mtt-sub-3':
        return '3 months';
      case 'ph-mtt-sub-12':
        return '12 months';
      case 'mvp-school':
        return 'MVP school';
      default:
        return 'unknown';
    }

  }
}
