import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {UserService} from '../services/user.service';
import {AppService} from '../services/app.service';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userService: UserService, private router: Router, private appService: AppService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('X-AUTH-TOKEN')) {
      req = req.clone({
        setHeaders: {
          'X-AUTH-TOKEN': this.userService.getToken()
        }
      });
    }

    return next.handle(req).pipe(catchError((error: HttpErrorResponse) => {
      const errorData = {
        reason: error && error.error.reason ? error.error.reason : '',
        status: error.status
      };
      if (errorData.status && errorData.status === 403) {
        localStorage.removeItem('token');
        this.userService.setToken('');
        this.appService.isAuthenticated.next(false);
        this.router.navigate(['login']);
      }
      return throwError(error);
    }));
  }
}
