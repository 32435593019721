import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {PaypalProductService} from '../../../services/paypal-product.service';

@Component({
  selector: 'app-buy-dialog',
  templateUrl: './buy-dialog.component.html',
  styleUrls: ['./buy-dialog.component.scss']
})
export class BuyDialogComponent implements OnInit {

  pupil: string;

  constructor(private productService: PaypalProductService,
              public dialogRef: MatDialogRef<BuyDialogComponent>,
              private userService: UserService) { }

  buy1Month() {
    this.productService.buyProduct('ph-mtt-sub-1');
    this.close();
  }

  buy12Month() {
    this.productService.buyProduct('ph-mtt-sub-12');
    this.close();
  }

  buy3Month() {
    this.productService.buyProduct('ph-mtt-sub-3');
    this.close();
  }

  buy1MonthForBitB() {
    this.productService.buyProduct('bitb-school-new');
    this.close();
  }

  openDiscord() {
    window.open('https://discord.gg/pokerhero', '_blank');
  }

  openSkype() {
    window.open('https://join.skype.com/invite/hUXNncXpufCR', '_blank');
  }

  ngOnInit() {
    this.pupil = this.userService.getUser().pupil;
  }

  close() {
    this.dialogRef.close();
  }

}
