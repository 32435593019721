import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {IconService} from './icon.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isAuthenticated = new BehaviorSubject<boolean>(false);

  menuOpened$ = new BehaviorSubject<boolean>(false);

  headerTitleTemplate: TemplateRef<any>;

  headerActionsTemplate: TemplateRef<any>;

  isMouseDown$ = new BehaviorSubject<boolean>(false);

  constructor(private translateService: TranslateService, private iconService: IconService) {
    this.iconService.initializeCardsIcons();
  }

  toggleMenu() {
    this.menuOpened$.next(!this.menuOpened$.getValue());
  }

  setLanguages() {

    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
    this.translateService.addLangs(['en', 'ru', 'fr']);
    this.translateService.setDefaultLang('en');
    this.translateService.use(lang);
  }

  changeLanguage(language: string) {

    this.translateService.use(language);
  }

  changeMouseDownState(newValue: boolean) {
    this.isMouseDown$.next(newValue);
  }
}
